.nav {
  position: fixed;
  top: 0;
  z-index: 10;

  width: 100%;
  padding: 20px 0;
  // background-image: linear-gradient(-180deg, #111111 0%, rgba(0, 0, 0, 0) 100%);

  background-color: rgba(0, 0, 0, 0.6);
}

.nav__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav__toggle {
  position: relative;

  display: none;
  order: 1;
  width: 40px;
  height: 40px;
  padding: 0;

  color: $color-default-green;

  background-color: transparent;
  border: none;
  cursor: pointer;

  @media (max-width: $vp-1024) {
    z-index: 10;

    display: block;
  }

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus {
      outline: none;
      opacity: 0.8;
    }
  }

  &:active {
    opacity: 0.6;
  }
}

.nav__toggle span {
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;

  display: block;
  width: 100%;
  height: 5px;

  background-color: #7ab96d;
  // box-shadow: 0 0 1px 0 #ffffff;
  transform: translateY(-50%);

  transition: all $trans-default;

  &:before,
  &:after {
    content: "";
    position: absolute;
    right: 0;
    left: 0;

    width: 100%;
    height: 5px;

    background-color: #7ab96d;
    // box-shadow: 0 0 1px 0 #ffffff;

    transition: all $trans-default;
  }

  &::before {
    top: -15px;
  }

  &::after {
    bottom: -15px;
  }
}

.nav__list {
  display: flex;
  padding-top: 3px;

  @media (max-width: $vp-1024) {
    position: absolute;
    top: -1000px;
    right: 0;
    left: 0;
    z-index: -1;

    display: block;
    padding: 100px 50px 50px;

    background-color: #ffffff;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.6);
    opacity: 0;

    transition: all $trans-default;
  }
}

.nav__item {
  padding: 0 10px;
}

.nav__link {
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  color: $color-default-white;
  text-transform: uppercase;
  text-shadow: 1px 1px 1px transparent;

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus {
      opacity: 0.8;
    }

    @media (min-width: 1025px) {
      &:hover,
      &:focus {
        text-decoration: 4px;

        outline: none;

        text-decoration-color: #f7b05e;
        text-decoration-line: underline;
        text-decoration-style: solid;
        text-underline-offset: 10px;
      }
    }
  }

  &:active {
    opacity: 0.6;
  }
}

// .nav--opened .nav__toggle {
//   margin-right: 15px;
// }

.nav--opened .nav__toggle span {
  transform: translateY(-50%) rotate(45deg);

  &::before {
    top: 0;

    background-color: transparent;
    // transform: rotate(-90deg);
  }

  &::after {
    bottom: 0;

    transform: rotate(-90deg);
  }
}

.nav--opened .nav__logo {
  z-index: 10;

  color: $color-default-green;
}

.nav--opened .nav__list {
  top: 0;
  z-index: 5;

  overflow: auto;

  opacity: 1;

  transition: opacity $trans-default;
}

.nav--opened .nav__item {
  margin-bottom: 20px;
}

.nav--opened .nav__link {
  color: #808080;
}
