.about {
  color: $color-default-white;
  // background-color: #262626;

  background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
    url("../img/about-bg.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-attachment: scroll;

  @supports (background-image: url("../img/about-bg.webp")) {
    background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
      url("../img/about-bg.webp");
  }
}

.about h2 {
  color: $color-default-white;
}
