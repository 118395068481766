.business {
  color: #333333;

  @media (max-width: 500px) {
    padding-bottom: 0;
  }
}

.business h3 {
  font-size: 22px;
  line-height: 150%;
  text-align: center;
  color: #333333;
  text-transform: uppercase;
}

.business__info {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0 50px;

  @media (max-width: $vp-1024) {
    grid-template-columns: 1fr;
  }
}

.business__info-item {
  position: relative;

  &:not(:last-child)::before {
    content: "";
    position: absolute;
    top: 80px;
    right: -25px;
    bottom: 80px;

    width: 4px;

    background-color: #cbcbcb;

    @media (max-width: $vp-1024) {
      display: none;
    }
  }
}

// .business__info-item {
//   padding-right: 50px;
// }

// .business__info-item:nth-child(2n) {
//   padding-right: 20px;
//   padding-left: 50px;

//   border-left: 2px solid #333333;
// }

.business__list {
  display: flex;
  flex-wrap: wrap;

  @media (max-width: $vp-1024) {
    display: block;
  }
}

.business__list-item {
  padding: 0 20px;

  @media (max-width: $vp-1024) {
    padding: 0;
  }

  & + .business__list-item {
    border-left: 2px solid #333333;

    @media (max-width: $vp-1024) {
      border: none;
    }
  }

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }
}
