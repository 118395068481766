// @font-face {
//   font-style: normal;
//   font-weight: 400;
//   font-family: "";

//   font-display: swap;
//   src:
//     url("../fonts/.woff2") format("woff2"),
//     url("../fonts/.woff") format("woff");
// }

@font-face {
  @include font("Open Sans", OpenSans-Regular, 400, normal);
}

@font-face {
  @include font("Open Sans", OpenSans-Bold, 700, normal);
}
