.info {
  margin-top: -7px;

  background-color: #ffffff;
}

.info__container {
  display: grid;
  grid-template-columns: 2fr 1fr;
  align-items: center;
  padding: 0;

  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
  }

  @media (max-width: $vp-768) {
    padding: 0;
  }
}

.info__item {
  padding: 50px;

  font-size: 18px;
  color: #808080;

  &--green {
    display: grid;
    grid-template-columns: 100px 1fr;
    gap: 20px;
    align-items: center;
    // max-width: 480px;
    padding: 50px 20px;

    font-weight: 700;
    font-size: 22px;
    line-height: 150%;
    color: $color-default-white;

    background-color: $color-default-green;

    @media (max-width: $vp-375) {
      grid-template-columns: 1fr;
    }
  }
}

.info__item svg {
  width: 100px;
  height: 100px;
}
