.btn {
  display: inline-block;
  min-height: 52px;
  margin: 10px 0;
  padding: 10px 40px;

  vertical-align: top;
  color: $color-default-white;

  background-color: $color-default-green;
  border: 1px solid $color-default-green;
  cursor: pointer;

  transition: all $trans-default;

  &--red {
    background-color: $color-torch-red;
  }

  &:disabled {
    opacity: 0.5;

    pointer-events: none;
  }

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus {
      background-color: $color-hover-green;
      border: 1px solid $color-hover-green;
    }
  }

  &:active {
    opacity: 0.8;
  }
}
