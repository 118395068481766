.footer {
  padding-top: 50px;
  padding-bottom: 0;

  font-size: 12px;
  line-height: 150%;
  color: #6d6d6d;
}

.footer__container {
  padding-bottom: 50px;
}

.footer__logo {
  margin: 0 auto 30px;

  color: $color-default-green;
}

.footer__contacts {
  padding: 30px 0;

  font-weight: 700;
  font-size: 16px;
  color: $color-default-white;

  background-color: #262626;
}

.footer__contacts-container {
  display: grid;
  align-items: center;

  gap: 20px;
  grid-template-columns: repeat(2, 1fr);

  @media (max-width: $vp-1024) {
    grid-template-columns: auto 1fr;
  }

  @media (max-width: $vp-768) {
    grid-template-columns: 1fr;
  }
}

.footer__contacts a {
  display: block;
  width: max-content;

  color: $color-default-white;

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus {
      text-decoration: 4px;

      outline: none;

      text-decoration-color: #f7b05e;
      text-decoration-line: underline;
      text-decoration-style: solid;
      text-underline-offset: 10px;
    }
  }

  &:active {
    opacity: 0.6;
  }
}

.footer address {
  margin-left: auto;

  font-style: normal;

  @media (max-width: $vp-768) {
    margin-left: 0;
  }
}
