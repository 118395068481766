.container {
  width: 100%;
  min-width: 320px;
  max-width: 1920px;
  margin: 0 auto;
  padding: 0 50px;

  @media (max-width: $vp-768) {
    padding: 0 20px;
  }
}
