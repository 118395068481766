.video {
  width: 100%;
}

.video__container {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  padding: 0;

  background-color: #000000;

  @media (max-width: $vp-768) {
    padding: 0;
  }
}

.video__content {
  position: relative;
  z-index: 2;

  grid-column: 1/-1;
  grid-row: 1/2;
  max-width: 465px;
  // max-width: 550px;
  padding-left: 100px;

  color: $color-default-white;
  text-shadow: 1px 1px 2px #000000;

  @media (max-width: $vp-1024) {
    grid-row: auto;
    max-width: none;
    padding: 50px;

    background-color: #000000;
  }

  @media (max-width: 700px) {
    padding-left: 40px;
  }
}

.video__content h1 {
  margin: 0;

  font-size: 56px;
  line-height: 100%;
  text-transform: uppercase;

  @media (max-width: 700px) {
    font-size: 5vw;
  }
}

.video__player-container {
  grid-column: 1/-1;
  grid-row: 1/2;

  background-color: #000000;
}

.video__player {
  display: none;
  width: 100%;
  height: 100%;
  max-height: 85vh;

  object-fit: cover;

  &--desktop {
    @media (min-width: 1024px) {
      display: block;
    }
  }

  &--tablet {
    @media (min-width: 599px) and (max-width: 1023px) {
      display: block;
    }
  }

  &--mobile {
    @media (max-width: 599px) {
      display: block;
      min-height: 50vh;
    }
  }
}

.video__date {
  display: inline-block;
  min-height: 27px;
}
