.sectors.indent {
  padding-top: 0;
}

.sectors__link-more {
  color: $color-hover-green;
  text-decoration: 2px;

  outline: none;

  text-decoration-color: $color-hover-green;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-underline-offset: 6px;

  &:hover,
  &:focus {
    color: $color-default-orange;

    text-decoration-color: $color-default-orange;
  }
}

.sectors__item-hide {
  display: none;
  // height: 0;

  // visibility: hidden;
  // opacity: 0;

  // transition: $trans-600;
}

.sectors__item.is-show {
  scroll-margin-top: 150px;
}

.sectors__item.is-show .sectors__link-more {
  display: none;
}

.sectors__item.is-show .sectors__item-hide {
  display: block;
  // height: auto;
  // overflow: auto;

  // visibility: visible;
  // opacity: 1;

  // transition: $trans-600;
}

.no-js .sectors__item-hide {
  display: block;
}

.no-js .sectors__link-more {
  display: none;
}
