// Colors
// ---------------------------------

// Default

$color-default-black: #000000;
$color-default-white: #ffffff;
$color-transparent: rgba(255, 255, 255, 0);
$color-default-blue: #003057;
$color-default-green: #59a748;
$color-default-orange: #f7b05e;

//  .st0{fill:#E9F4E7;}
//  .st1{fill:#C9E2C3;}
//  .st2{fill:#7AB96D;}
//  .st3{fill:#59a748;}
//  .st4{fill:#396B2E;}

// Project palette
// для нейминга цветов используем https://www.htmlcsscolor.com/hex/334482

$color-neon-blue: #2c39f2;
$color-torch-red: #ff1553;
$color-hover-green: #286622;

// Gradient

$black-to-right: linear-gradient(
  90deg,
  rgba(0, 0, 0, 0.75) 0%,
  rgba(0, 0, 0, 0) 75%
);

// Typography
// ---------------------------------

// переменная используемая в html для подключения скейлинга
$fz-default: 16px;

// у некоторых шрифтов в ios возникают проблемы с символом рубля
// https://www.artlebedev.ru/kovodstvo/sections/159/#13
// $ff-rouble: "Rouble", "Arial", sans-serif;

$ff-placeholder: "Open Sans", "Arial", sans-serif;

// Animation
// ---------------------------------

$tf-default: ease;
$trans-default: 0.5s $tf-default;

// если требуется изинг отличный от $tf-default, то переменную называем не цифрой
$trans-modal: 0.6s cubic-bezier(0.55, 0, 0.1, 1);

$trans-600: 0.6s $tf-default;

// Viewports
// ---------------------------------

$vp-320: 320px;
$vp-375: 375px;
$vp-768: 768px;
$vp-1024: 1024px;
$vp-1280: 1280px;
$vp-1440: 1440px;
$vp-1920: 1920px;

// Retina
// ---------------------------------

$retina-dpi: 144dpi;
$retina-dppx: 1.5dppx;
