.logo {
  display: block;
  width: 180px;
  height: 62px;

  color: $color-default-white;
}

.logo svg {
  display: block;
  width: 100%;
  height: 100%;

  fill: currentColor;
}
