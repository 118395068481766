.intro {
  color: #ffffff;

  background-image: linear-gradient(rgba(0, 0, 0, 0.77), rgba(0, 0, 0, 0.77)),
    url("../img/intro-bg.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-attachment: scroll;

  @supports (background-image: url("../img/intro-bg.webp")) {
    background-image: linear-gradient(rgba(0, 0, 0, 0.77), rgba(0, 0, 0, 0.77)),
      url("../img/intro-bg.webp");
  }
}

.intro__container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 50px;
  align-items: center;

  @media (max-width: $vp-1024) {
    grid-template-columns: 1fr;
    gap: 0;
  }
}

.intro h3 {
  font-size: 37px;
  line-height: 150%;
  color: #add85d;
  text-transform: uppercase;

  @media (max-width: $vp-1024) {
    text-align: center;
  }

  @media (max-width: $vp-375) {
    font-size: 7vw;
  }
}
